<template>
  <div>
    <div class="panel-inr-box">
      <div class="box-top-wrap d-flex align-center justify-space-between">
        <div class="item-row">
          <button class="text-capitalize
              btn customBtn pattern-btn
              white--text" @click="$router.push('/dashboard/message-center')">
            <span>{{ $t("common.back") }}</span>
          </button>
          <h3 class="box-title green-text post-thread-text">{{
            postThread.post ? postThread.post.message : ""
          }}</h3>
        </div>
      </div>
      <div class="custom-card vehicle-car single-message-box">
        <div class="custom-box">
          <div class="message-chat-body">
            <div id="chatWindow" class="flex-grow-1 overflow-y-auto custom-chat-height">
              <template v-for="(message, i) in messages">
                <div :key="i" :class="message.sender.id == user.id
                  ? 'writer-users chat-message-groups'
                  : 'chat-message-groups'
                  ">
                  <div class="chat-messages">
                    <div class="d-flex">
                      <div class="user-avtar">
                        <v-avatar class="user-avtar-icon rounded-circle" size="40">
                          <!-- {{ message.sender.name }} -->
                          <span>{{ message.sender.name.split(' ').slice(0, 2)
                            .map(word => word.charAt(0).toUpperCase())
                            .join('') }}</span>
                        </v-avatar>
                      </div>
                      <div class="ml-3">
                        <div class="from-date">
                          {{ message.sent_at | DateTimezoneFilter }}
                        </div>
                        <pre class="pre-wrap chattext">{{ message.body }}</pre>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <ApiErrorMessage :message="apiErrorMessage" />
          <div class="message-chat-footer">
            <div class="flex-shrink-1 d-flex align-center p-relative">
              <v-textarea v-model="message" :placeholder="$t('messages.typeSomething')" no-details solo rows="2"
                :loading="loading" hide-details="auto" name="input-7-4" spellcheck="false">
              </v-textarea>
              <emoji-picker @emoji="append" :search="search">
                <div class="emoji-invoker" slot="emoji-invoker" slot-scope="{ events: { click: clickEvent } }"
                  @click.stop="clickEvent">
                  <svg class="emojipicker-messages" height="24" viewBox="0 0 24 24" width="24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z" />
                  </svg>
                </div>
                <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                  <div class="emoji-picker message-emoji-picker">
                    <div class="emoji-picker__search">
                      <input type="text" v-model="search" v-focus />
                    </div>
                    <div>
                      <div v-for="(emojiGroup, category) in emojis" :key="category">
                        <h5>{{ category }}</h5>
                        <div class="emojis">
                          <span v-for="(emoji, emojiName) in emojiGroup" :key="emojiName" @click="insert(emoji)"
                            :title="emojiName">
                            {{ emoji }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </emoji-picker>
              <v-btn :disabled="!message" @click="submitPostReply" min-width="10" min-height="56"
                class="btn customBtn pattern-btn send-btn ml-2" elevation="0">
                <span><img class="chat-icon-m" src="../assets/img/send.svg" /></span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SocketMixin from "@/mixins/SocketMixin";

export default {
  name: "Messages",
  components: {
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },
  mixins: [SocketMixin.listeners],
  data: () => ({
    search: "",
    loading: false,
    loadingMessages: false,
    activeChat: 1,
    message: "",
    apiErrorMessage: null,
  }),

  computed: {
    ...mapGetters({
      postThread: "postThread/getPostThread",
      user: "user/getUser",
      messages: "messages/getMessages",
    }),
  },

  async mounted() {
    await this.fetchMessages();
  },

  methods: {
    ...mapActions({
      getPostThread: "postThread/getPostThread",
      getMessages: "messages/getMessages",
      createMessages: "messages/createMessages",
    }),

    append(emoji) {
      this.message += emoji;
    },

    async fetchMessages() {
      this.loadingMessages = true;
      try {
        await this.getPostThread(this.$route.params.postThreadId);
        await this.getMessages(this.$route.params.postThreadId);
        this.setScrollBottom();
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loadingMessages = false;
      }
    },

    async submitPostReply() {
      this.loading = true;
      try {
        const recipientId =
          this.postThread.owner.id === this.user.id
            ? this.postThread.recipient.id
            : this.postThread.owner.id;
        await this.createMessages({
          post_id: this.postThread.post.id,
          recipient_id: recipientId,
          body: this.message,
        });
        this.message = "";
        await this.getMessages(this.$route.params.postThreadId);
        this.setScrollBottom();
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },

    setScrollBottom() {
      const objDiv = document.getElementById("chatWindow");
      objDiv.scrollTop = objDiv.scrollHeight;
    },
  },
};
</script>
